import {
  LinkComponentProvider,
  LinkComponentProps,
} from "@apollosproject/canvas-ui-web";
import { Link as RemixLink, useParams } from "@remix-run/react";
import { ReactNode, forwardRef } from "react";

interface LinkingProviderProps {
  children: ReactNode;
}

const LinkComponent = forwardRef(function LinkComponent(
  { to, href, isExternal, ...props }: LinkComponentProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const params = useParams();
  if ((isExternal && href) || !to) {
    return <a ref={ref} href={href} {...props} />;
  }
  if (typeof to === "object") {
    if (!to.contentId) return null;
    return (
      <RemixLink
        ref={ref}
        to={`/${params.handle}/c/${to?.contentId.replace(":", "-")}`}
        {...props}
      />
    );
  }
  return <RemixLink ref={ref} to={to} {...props} />;
});

export const LinkingProvider: React.FC<LinkingProviderProps> = ({
  children,
}) => {
  return (
    <LinkComponentProvider linkComponent={LinkComponent}>
      {children}
    </LinkComponentProvider>
  );
};
