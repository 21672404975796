import { MutationProvider as MutationContextProvider } from "@apollosproject/canvas-ui-web";
import { useSubmit, useParams } from "@remix-run/react";

const addComment = async ({ text, params, submit }) => {
  return submit(
    {
      _action: "addComment",
      text,
    },
    {
      method: "POST",
      action: `/${params.handle}/c/${params.contentId}`,
    }
  );
};

const interactWithMedia = async ({ media, action, data, submit, params }) => {
  return submit(
    {
      _action: "interactWithMedia",
      nodeId: media.id,
      interactionAction: action,
      data: JSON.stringify(data),
    },
    {
      method: "POST",
      action: `/${params.handle}/c/${params.contentId}`,
    }
  );
};

const MutationProvider = ({ children }: MutationProviderProps) => {
  const params = useParams();
  const submit = useSubmit();

  return (
    <MutationContextProvider
      onAddComment={({ text }: { text: string }) =>
        addComment({ text, params, submit })
      }
      onInteractWithMedia={({
        media,
        action,
        data,
      }: {
        media: { id: string };
        action: string;
        data: Record<string, unknown>;
      }) => interactWithMedia({ media, action, data, submit, params })}
    >
      {children}
    </MutationContextProvider>
  );
};

export { MutationProvider };
